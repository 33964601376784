<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>New User <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">Name</label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone</label>
								<div class="col-sm-10">
									<input type="text" v-model="accountRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
								</div>
							</div>
							<div class="form-group row">
								<label for="inputEmail" class="col-sm-2 col-form-label text-danger">Email</label>
								<div class="col-sm-10">
									<input type="email" v-model="accountRequest.email" @blur="validationRule()"
										class="form-control" id="inputEmail">
									<small class="text-danger">{{validationData.email}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputPassword" class="col-sm-2 col-form-label text-danger">Password</label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.password" @blur="validationRule()"
										class="form-control" id="inputPassword">
									<small class="text-danger">{{validationData.password}}</small>
								</div>
							</div>
							<div class="form-group row">
								<label for="inputConfirmPassword" class="col-sm-2 col-form-label text-danger">Re-Password</label>
								<div class="col-sm-10">
									<input type="password" v-model="accountRequest.confirm" @blur="validationRule()"
										class="form-control" id="inputConfirmPassword">
									<small class="text-danger">{{validationData.confirm}}</small>
								</div>
							</div>

							<div class="form-group row" style="display: none;">
								<label for="inputImage" class="col-sm-2 col-form-label text-danger">Image</label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="accountRequest.image" v-bind:src="accountRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'accountCreate',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				departmentList : [],
				roleList : [],
				permissionList : [],
				permissionDev: false,
				checkGroupName: [],
				checkPermissionList:  [],
				accountRequest: {
					roleId: "",
					name: "",
					email: "",
					phone: "",
					image: "",
					password: "",
					roleName: '',
					checkPermissionList:  [],
				},
				validationData: {
					name: "",
					email: "",
					password: "",
					confirm: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.accountRequest.id = to.params.id
					await this.accountDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				memberFieldsAction: 'memberFieldsAction',
				permissionListAction: 'permissionListAction',
				roleListAction: 'roleListAction',
				memberStoreAction: 'memberStoreAction',
			}),
			selectAll: function(selectPermission, selectGroup) {
				let check = this.checkGroupName.includes(selectGroup)
				if(check){
					selectPermission.forEach(el => {
						if( this.accountRequest.checkPermissionList.includes(el.permission_name_slug)){
							var index = this.accountRequest.checkPermissionList.indexOf(el.permission_name_slug);
							if (index !== -1) this.accountRequest.checkPermissionList.splice(index, 1);
						}
					});
					var indexGroup = this.checkGroupName.indexOf(selectGroup);
					if (indexGroup !== -1) this.checkGroupName.splice(indexGroup, 1);

				}else{
					selectPermission.forEach(el => {
						if( !this.accountRequest.checkPermissionList.includes(el.permission_name_slug)){
							this.accountRequest.checkPermissionList.push(el.permission_name_slug);
						}
					});
					this.checkGroupName.push(selectGroup);
				}

			},
			async selectedRole(){
				if(this.accountRequest.roleId == ''){
					this.permissionDev = false
				}else{
					if(this.accountRequest.roleId == "create-permission"){
						let arrLength = this.accountRequest.checkPermissionList.length
						this.accountRequest.checkPermissionList.splice(0, arrLength)
						this.accountRequest.roleName = ''
						this.permissionDev = true
					}else{
						this.permissionDev = true

						let option = {
							role: this.accountRequest.roleId
						}
						await this.roleListAction({
							...option
						}).then(res => {
							this.accountRequest.checkPermissionList = res.data.data.permissions
							this.accountRequest.roleName = res.data.data.role
						}).catch(err => this.isLoading = true)

					
					}
					}

			},
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.accountRequest.image = e.target.result
					this.validationRule()
				}
			},
			async getFields(){
				let option = {
					fields: {id: this.accountRequest.id, type: "store"}
				}
				await this.memberFieldsAction({
					...option
				}).then(res => {
					this.roleList = res.data.data.role
				}).catch(err => this.isLoading = true)
			},
			async getPermission(){
				await this.permissionListAction().then(res => {
					this.permissionList = res.data.data
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.accountRequest
				await this.memberStoreAction({
					...option
				}).then(res => {
					this.$router.replace({ path: 'member-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''} }).catch(()=>{})
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.accountRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.accountRequest.email == "") {
					this.validationData.email = "The email field is required."
					isValidation.push(true)
				} else {
					this.validationData.email = ""
					isValidation.push(false)
				}
				if(this.accountRequest.password == ""){
					this.validationData.password = "The password field is required."
					isValidation.push(true)
				}else{
					this.validationData.password = ""
					isValidation.push(false)
				}
				if(this.accountRequest.confirm == ""){
					this.validationData.confirm = "The re-passwored field is required."
					isValidation.push(true)
				}else{
					this.validationData.confirm = ""
					isValidation.push(false)
				}
				if(this.accountRequest.password != this.accountRequest.confirm){
					this.validationData.confirm = "The password field does not match."
					isValidation.push(true)
				}else{
					this.validationData.confirm = ""
					isValidation.push(false)
				}
                
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.getFields()
			this.getPermission()
		}
	}
</script>

<style scoped lang="scss">
/* Style to center grid column */
    .col-centered{
        float: none;
        margin: 0 auto;
    }
    
    /* Some custom styles to beautify this example */
    // .row{
    //     background: #dbdfe5;
    // }
    .demo-content{
        padding: 25px;
        font-size: 18px;
        background: #abb1b8;
    }
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>